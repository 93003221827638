<template>
      <div class="DMsgBox" :class="{Show:(animation === 'Show'),Hide:(animation === 'Hide')}" :id="modal" v-show="show">
          <div class="bg"></div>
          <div class="body">
              <div class="image"><slot name="image"></slot></div>
              <div class="content">
                  <div>
                    <div class="text">
                        <slot name="content"></slot>
                        <div class="footer"><slot name="footer"></slot></div>
                    </div>
                    <div class="bg"><img :src="$parent.assets.img.box"/></div>
                  </div>
              </div>
          </div>
      </div>
</template>

<script>
import $ from "jquery";
export default {
  name: 'dMsgBox',
  props: ['modal'],
  data(){
    return {
        show : false,
        animation : "hide",
        transition : {
            in : 1000,
            out : 1000
        }
    }
  },
  watch: {
      show(){
          $(document).ready(()=>{
            $(window).trigger("resize")
          })
      }
  },
  mounted () { 
      $(document).ready(()=>{
          $(window).on("resize",()=>{
              this._responsive()
          })
          $(window).trigger("resize")
      })
  }, 
  methods:{
        _responsive(){
            var page = $(window);
            var main = $("#"+this.modal+".DMsgBox > .body");
            var scale = 1;
            if(this.$parent.theme == 3){
                return false
            }

            main.css({"transform":"translate(-50%,-50%) scale("+scale+")","transform-origin": "50% 50%"});
            while((main.get(0).getBoundingClientRect().height >= page.outerHeight()) || (main.get(0).getBoundingClientRect().width >= page.outerWidth())){
                 scale -= .01;
                 main.css({"transform":"translate(-50%,-50%) scale("+scale+")","transform-origin": "50% 50%"});
            }
        },
        _show(){
            return new Promise((resolve)=>{
                this.show = true
                this.animation = "Show"
                if(this.$parent.data.project.fast_mode){
                    this.transition.in = 0
                }
                setTimeout(()=>{
                    resolve()
                },this.transition.in)    
            })
        },
        _hide(){
             return new Promise((resolve)=>{
                this.animation = "Hide"
                if(this.$parent.data.project.fast_mode){
                    this.transition.out = 0
                }
                setTimeout(()=>{
                    this.show = false
                    resolve()
                },this.transition.out)    
            })           
        }
  }
}
</script>
