import $ from "jquery";
let _ = require('lodash');
import Api from '@/services/Api'

export let MIX_data = {
    data(){
        return {
            token : null,
            data : {
                project : {},
                primes : [],
                targets : [],
            },
            setup : {
                targets : [],
                displayTargets : [],
                targetSize : 50,
            }
        }
    },
    methods : {
        _copyObj(obj){
            return JSON.parse(JSON.stringify(obj))
        },
        _initProject(){
            return new Promise((resolve)=>{
                this._getToken().then(()=>{
                    this._getProject().then(()=>{
                        this._setupTheme().then(()=>{
                            this._setupProject().then(()=>{
                                Promise.all([this._setupTargets()]).then(()=>{
                                    resolve()
                                })
                            })
                        })
                    })
                })
            })
        },
        _setupTheme(){
            return new Promise((resolve)=>{
                this.fast_mode = this.data.project.fast_mode
                this.theme = this.data.project.theme
                if(this.theme == 2){
                    this.assets.img = {
                        hint : require('@/assets/img/hint.png'),
                    }
                }
                else if(this.theme == 3){
                    this.assets.img = {
                        hint : require('@/assets/img/hint.png'),
                    }
                }
                else if(this.theme == 4){
                    this.assets.img = {
                        hint : require('@/assets/img/hint.png'),
                    }
                }
                else{
                    this.assets.img = {
                        kid1 : require('@/assets/img/kid1.png'),
                        kid2 : require('@/assets/img/kid2.png'),
                        kid3 : require('@/assets/img/kid3.png'),
                        kid4 : require('@/assets/img/kid4.png'),
                        hint : require('@/assets/img/hint.png'),
                        box : require('@/assets/img/msg.png'),
                    }
                }
                this.$refs['bg'].show=true
                resolve()
            })
        },
        _setupProject(){
            return new Promise((resolve)=>{
                    let proj = this.data.project
                    if(this.user.prac_switch){
                        proj.prac_switch = this.user.prac_switch
                    }
                    if(this.user.auto_landscape == 1){
                        proj.auto_landscape = true
                    }else if(this.user.auto_landscape == 0){
                        proj.auto_landscape = false
                    }

                    if(this.user.main_switch){
                        proj.main_switch = this.user.main_switch
                    }
                    if(this.user.title == 1){
                       this.title.show = true
                       this.title.content = proj.eaat_name
                    }
                    if(this.user.target_display){
                        proj.target_display = this.user.target_display
                    }
                    this._setupHomePos().then(()=>{
                        this._setupCSS().then(()=>{
                            resolve()
                        })
                    })
            })
        },
        _setupHomePos(){
            return new Promise((resolve)=>{
                let proj = this.data.project
                let targets = this._copyObj(this.data.targets)
                if(this.user.home){
                    proj.brand_position = this.user.home
                }
                let home = null
                let HT = targets.filter((target)=>{
                    return target.target_type === "Home"
                })
                let AT = targets.filter((target)=>{
                    return target.target_type === "Away"
                })
                if(proj.shuffle_home == 1){
                    HT = _.shuffle(HT)
                }
                if(proj.shuffle_away == 1){
                    AT = _.shuffle(AT)
                }               
                switch(proj.brand_position){
                    case "S":
                        home = _.shuffle(["L","R"])[0]
                    break
                    case "S2":
                        home = proj.brand_position
                        this.setup.targets = _.shuffle(targets)
                    default:
                        home = proj.brand_position
                }
                if(home != "S2"){
                    if(home == "L"){
                        this.setup.targets = HT.concat(AT);
                    }else{
                        this.setup.targets = AT.concat(HT);
                    }
                }

                this.data.project['home'] = home
                resolve()
            })       
        },
        _setupCSS(){
            return new Promise((resolve)=>{
                $(document).ready(()=>{
                    $(document.body).append('<style>'+this.data.project.custom_css+'</style>')
                    resolve()
                })
            })
        },
        _setupTargets(){
            return new Promise((resolve)=>{
                if(this.data.project.pairwise){
                    this.setup.targetSize = 50
                    resolve()
                    return false
                }
                this.setup.targetSize = 100 / this.setup.targets.length
                resolve()
            })    
        },
        _getToken(){
            return new Promise((resolve)=>{
                Api().post('issue_token',{username:'superadmin@eaat.splitsecondsurveys.co.uk',password:'skkdn*shHhay566637ahabhhsAh452'}).then((res)=>{
                    this.token = res.data.token
                    resolve()
                })
            })
        },
        _getProject(){
            return new Promise((resolve)=>{
                Api().post('eaat/'+this.user.code,this.user,{headers: {'Authorization': 'Bearer '+this.token}}).then((res)=>{
                    
                    //STATIC 
                    if(!res.data.project.target_display){
                        res.data.project.target_display = 'inline'                    
                    }


                    this.data = res.data

                    //CONVERT INT
                    this.data.project.block_break = parseInt(this.data.project.block_break)
                    this.data.project.block_count = parseInt(this.data.project.block_count)
                    this.data.project.retrial_limit = parseInt(this.data.project.retrial_limit)
                    this.data.project.main_first_trial_delay = parseInt(this.data.project.main_first_trial_delay)
                    this.data.project.prac_first_trial_delay = parseInt(this.data.project.prac_first_trial_delay)

                    resolve()
                }).catch(error => {
                    console.log(error)
                })
                
                
            })
        },
        _savingData(){
            let proj = this.data.project
            let form = {
                eaat_id:proj.eaat_id,
                p_id:this.user.id,
                data:JSON.stringify(this.$refs['main'].record.final),
                url:window.location.href,
                browser:this.browser.name,
                browser_version:this.browser.version,
                device:this.device.type,
                platform:this.browser.os,
                datetaken:proj.datetaken,
                home:proj.home,
                user_agent:navigator.userAgent,
                setId:this.data.setId
            }
            this.$refs.save._show(true,proj.end_txt).then(()=>{
                Api().post('record/save',form,
                {headers: {'Authorization': 'Bearer '+this.token}}).then((res)=>{
                    this._redirect()
                }).catch(error => {
                    console.log(error)
                    setTimeout(()=>{
                        this._savingData()
                    },2000)
                })
            })           
        },
        _passURI(_url_){
            let user = this._copyObj(this.$route.query)
            delete user.code
            //delete user.prac_switch
            if (this.data.project.pass_url == 1){
              let uri = $.param(user)
              let append = "?"
              if(_url_.includes("?")){
                append = "&"
              }
              _url_ += append+uri
            }
            _.forOwn(user, function(value, key) { 
                _url_ = _url_.replace('$'+key, value)
            })
            return _url_
        },
        _passEaatVal(_url_){
            let user = this._copyObj(this.$route.query)
            delete user.code
            //delete user.prac_switch
            if (this.data.project.pass_eaat_val == 1){
              let append = "?"
              if(_url_.includes("?")){
                append = "&"
              }
              let uri = ""
              let data = this._copyObj(this.$refs['main'].record.final)
              data = _.orderBy(data,['order','block'],['asc','asc'])
              data.map((v)=>{
                uri += "&"+this.data.project.eaat_val_prefix+v.order+"_bl"+v.block+"="+v.choice.target_value
              })
              uri = uri.slice(1)
              _url_ += append+uri
            }
            return _url_
        },
        _passSets(_url_){
            if(this.data.sets.length <= 0){
                return _url_
            }
            let append = "?"
            if(_url_.includes("?")){
              append = "&"
            }
            _url_ += append+"sets="+JSON.stringify(this.data.sets)
            return _url_
        },
        _redirect(){
            let proj = this.data.project
            let next = this._passURI(proj.redirect_url)
            next = this._passEaatVal(next)
            next = this._passSets(next)
            this.$refs.save._show(true,proj.end_txt).then(()=>{
                this.$router.push({ name: 'redirect', query: { path: next } })
                //location.href = next
            })
        }
    }
}