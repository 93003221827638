<template>
<transition name="ATargetCircle">
        <div class="dTargetCircle" :boxSize="$parent.$parent.setup.displayTargets.length" v-if="$parent.show">
            <div class="body">
                <div class="targets">
                    <div v-for="(target, key) in _setTargetSlot($parent.$parent.setup.displayTargets)" :key="key" :slotid="target.slot" class="item d__DropItem" :tID="target.target_id">
                        <div class="body">
                            <div class="target image" v-if="(target.target_img)"><img :src="target.target_img"/></div>
                            <div class="target word" :fs="$parent.$parent.data.project.target_fontsize" v-else v-html="target.target_name"></div>
                        </div>
                    </div>
                </div>
                <div class="dHeaderCircle" v-html="$parent.$parent.data.project.header_txt"></div>
            </div>
        </div>
</transition>
</template>

<script>
import $ from "jquery"
var _ = require('lodash')

export default {
  name: 'dTargetCircle',
  components: {},
  data(){
      return {
          box : {
              size:8,
              show:true
          }
      }
  },
  methods :{
         _setTargetSlot(targets){
                let slots = [1,2,3,4,5,6,7,8]
                this.box.size = targets.length
                switch(targets.length){
                    case 2:
                    slots = [4,5]
                    break;
                    case 3:
                    slots = [2,6,8]
                    break
                    case 4:
                    slots = [1,3,6,8]
                    break
                    case 5:
                    slots = [1,2,3,6,8]
                    break         
                    case 6:
                    slots = [1,4,6,3,5,8]
                    break       
                    case 7:
                    slots = [1,2,3,4,5,6,8]
                    break                       
                }
                targets.map((v,k)=>{
                    v["slot"] = slots[k]
                })
                return targets
        },
  }
}

</script>

<style scoped>


</style>